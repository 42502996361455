export const Bio = {
  title: "Greetings, I am Bojan",
  roles: [
    "Web Development",
    "Learning about Tech",
    "Gaming"
  ],
  description:
    "Passionate software and web developer from Serbia\nInterested in Frontend development, embedded and AI",
  github: "https://github.com/Turtlebole",
  resume:
    "",
  linkedin: "https://www.linkedin.com/in/babicbojan/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "Angular",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Angular-Dark.svg",
      },
      {
        name: "React Js",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/React-Dark.svg",
      },
      {
        name: "Vue Js",
        image:
            "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/VueJS-Dark.svg",
      },
      {
        name: "Nuxt Js",
        image:
            "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/NuxtJS-Dark.svg",
      },
      {
        name: "Next Js",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/NextJS-Dark.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/JavaScript.svg",
      },
      {
        name: "TypeScript",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/TypeScript.svg",
      },
      {
        name: "HTML",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/HTML.svg",
      },
      {
        name: "CSS",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/CSS.svg",
      },
      {
        name: "Bootstrap",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Bootstrap.svg",
      },
      {
        name: "Tailwind",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/TailwindCSS-Dark.svg",
      },
     
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "C#",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/CS.svg",
      },
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Java-Dark.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Python-Dark.svg",
      },
      {
        name: "Go",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/GoLang.svg",
      },
    ],
  },
  {
    title: "Database",
    skills: [
      {
        name: "MsSQL",
        image:
          "http://upload.wikimedia.org/wikipedia/de/8/8c/Microsoft_SQL_Server_Logo.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/MySQL-Dark.svg",
      },
      {
        name: "Postgresql",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/PostgreSQL-Dark.svg",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/MongoDB.svg",
      },
      {
        name: "Firebase",
        image: "https://raw.githubusercontent.com/tandpfun/skill-icons/65dea6c4eaca7da319e552c09f4cf5a9a8dab2c8/icons/Firebase-Dark.svg",
      },
    ]
  },
];

export const projects = [
  {
    id: 1,
    title: "Laplacia",
    date: "2023 - XX",
    description:
      "Web application used to assist writers in visualizing and structuring their stories, its utilizing the .NET framework and Angular. The project is created using domain-driven design.",
    image:
      "https://i.imgur.com/um67xkT.png",
    tags: [
      "C#",
      ".NET",
      "PostgreSQL",
      "Angular",
      "Docker",
    ],
    category: "web",
    github: "https://github.com/ProjectLaplacia",
    webapp: "#",
  },
  {
    id: 2,
    title: "Airbnb Clone",
    date: "2023 - 2023",
    description:
      "Fullstack web clone of Airbnb written as a school project",
    image:
      "https://i.imgur.com/um67xkT.png",
    tags: [
      "Go",
      "Angular",
      "MongoDB",
      "CassandraDB",
      "Docker"
    ],
    category: "web",
    github: "https://github.com/Turtlebole/airbnb-msa-mockup",
    webapp: "#",
  },
  {
    id: 3,
    title: "Fitness Manager",
    date: "2023 - 2023",
    description:
      "A small desktop application used to simulate fitness center management. Users have a graphical interfacte for tracking their appointments as well as their progress, After the sessions are completed the users can review their mentor.",
    image:
      "https://i.imgur.com/um67xkT.png",
    tags: [
      "C#",
      ".NET",
      "MicrosoftSQL",
      "Windows Forms",
    ],
    category: "desktop",
    github: "https://github.com/Turtlebole/fitness-center-manager",
    webapp: "#",
  },
  {
    id: 4,
    title: "Laplacia",
    date: "2023 - XX",
    description:
      "Web application used to assist writers in visualizing and structuring their stories",
    image:
      "https://i.imgur.com/um67xkT.png",
    tags: [
      "C#",
      ".NET",
      "PostgreSQL",
      "Angular",
      "Docker",
    ],
    category: "web",
    github: "https://github.com/ProjectLaplacia",
    webapp: "#",
  },
  {
    id: 5,
    title: "Laplacia",
    date: "2023 - XX",
    description:
      "Web application used to assist writers in visualizing and structuring their stories",
    image:
      "https://i.imgur.com/um67xkT.png",
    tags: [
      "C#",
      ".NET",
      "PostgreSQL",
      "Angular",
      "Docker",
    ],
    category: "web",
    github: "https://github.com/ProjectLaplacia",
    webapp: "#",
  },
];